
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  useColorMode, Image, Text
} from '@chakra-ui/react';
import {NavLink } from 'react-router-dom';
import { HamburgerIcon, CloseIcon} from '@chakra-ui/icons';
import {FaHome} from 'react-icons/fa';
import './header.css';
import sokk from '../pictrues/sokk_svart.png'




export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <>
      <Box bg={'yellow.300'} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              <NavLink className={'navlink'}  to={"./"}><FaHome py={5}/></NavLink>
              <NavLink className={'navlink'}  to={"/about"}>Om oss</NavLink>
              <NavLink className={'navlink'}  to={"/photos"}>Bilder</NavLink>
              <NavLink className={'navlink'}  to={"/members"}>Medlemmer</NavLink>
              <NavLink className={'navlink'}  to={'https://anmodning.samfundet.no/'}>Booking</NavLink>
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}  alignItems={'center'}>
                <NavLink display={{ base: 'none', md: 'flex' }} className={'navlink'}  to={"/intern"}> <Text  display={{ base: 'none', md: 'flex' }}>Intern</Text></NavLink>
                <NavLink to={"./"}>
                  <Image src={sokk} style={{height:'8vh', width: 'auto'}}></Image>
                </NavLink>
            </Stack>
          </Flex>

        </Flex>   
        {isOpen ? (
              <div className="hamburger_menu">
                <div className="button">
                    <IconButton
                      size={'md'}
                      icon={<CloseIcon />}
                      aria-label={'Close Menu'}
                      display={{ md: 'none' }}
                      onClick={onClose}
                    />
                  </div>
                  <NavLink className={'navlink'} to="/about" onClick={onClose}>Om oss</NavLink>
                  <NavLink className={'navlink'}  to="/photos" onClick={onClose}>Bilder</NavLink>
                  <NavLink className={'navlink'}  to={"/members"} onClick={onClose}>Medlemmer</NavLink>
                  <NavLink className={'navlink'}  to={'https://anmodning.samfundet.no/'} onClick={onClose}>Booking</NavLink>
                  <NavLink className={'navlink'} id={'intern'} to={"/intern"} onClick={onClose}>Intern</NavLink>
              </div>
          ) : null} 
      </Box>
    </>
  );
}