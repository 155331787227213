import * as React from 'react'
import {Suspense} from 'react'
import {ChakraProvider,ColorModeScript} from '@chakra-ui/react'
import Header from './compontens/header.js';
//import Opptak from './compontens/opptak.js';
import Footer from './compontens/footer.js';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import data from "./data/carouselData.json";
import theme from './theme'
const About = React.lazy(() => import('./pages/about'))
const Photos = React.lazy(() => import('./pages/galleri'))
const Members= React.lazy(() => import('./pages/members'))
const Home = React.lazy(() => import('./pages/home'))
const Intern = React.lazy(() => import('./pages/intern'))

function App() {


  return (
    <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider>
    <Router>
      <Header />
      <Suspense fallback={<p> Loading...</p>}>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About/>} />
          <Route path="photos" element={<Photos />} />
          <Route path="members" element={<Members />}/>
          <Route path="intern" element={<Intern />} />
        </Routes>
      </Suspense>
    </Router>


    <div className="App">
    <Footer />
    </div>
    </ChakraProvider>
    </>
  );
}

export default App;

