import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
  } from '@chakra-ui/react';
  import { FaInstagram, FaHeart, FaYoutube,FaFacebook} from 'react-icons/fa';
  import { ReactNode } from 'react';
  
  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'blackAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('white', 'white'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function SmallWithSocial() {
    return (
      <Box
        bg={useColorModeValue('yellow.300', 'yellow.300')}
        color={useColorModeValue('gray.700', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>Du kan kontakte oss på = vk(æt)samfundet.no </Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Singel'} href={'mailto:vk-singel(æt)samfundet.no'}>
              <FaHeart color={'#F4989C'} />
            </SocialButton>
            <SocialButton  label={'YouTube'} href={'https://www.youtube.com/channel/UC0cnEy0njxrTovXFCYLieZA/videos?view=0&sort=dd&shelf_id=0'}>
              <FaYoutube color={'red'} />
            </SocialButton>
            <SocialButton label={'Facebook'} href={'mailto:vk-singel(æt)samfundet.no'}>
              <FaFacebook color={'blue'} />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'https://www.instagram.com/videokomiteen/'}>
              <FaInstagram color={'purple'}/>
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    );
  }